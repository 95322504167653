import requester from '@/axios';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import CoursePath, {
  CourseCategoryEnum,
  CourseTypeEnum,
  ICoursePath,
} from '@/models/Course';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

type Ordering<T extends string> = T | `-${T}`;

export type CoursePathOrdering =
  | Ordering<'version'>
  | Ordering<'class_count'>
  | Ordering<'created_at'>
  | Ordering<'last_update'>
  | Ordering<'rating'>;

export interface ListCoursePathFilters extends BaseFilters {
  category?: CourseCategoryEnum[];
  courseType?: CourseTypeEnum;
  status?: VersioningStatusEnum[];
  uniqueCourse?: boolean;
  slug?: string;
  name?: string;
  courseId?: number[];
  ordering?: CoursePathOrdering;
  versionMin?: number[];
  versionMax?: number[];
}

export const listCoursesPath = async (params: ListCoursePathFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<ICoursePath>>(
    'courses/',
    {
      params,
    },
  );

  return data;
};

export const getCoursePath = async (slugCourseName?: string) => {
  const { data } = await requester().get<ICoursePath>(
    `courses/${slugCourseName}/`,
  );

  return data;
};

export type UpdateCoursePath = Partial<Pick<CoursePath, 'status' | 'isbnCode'>>;

export const updateCoursePath = async (
  slug: string,
  changes: UpdateCoursePath,
) => {
  const { data: coursePath } = await requester().patch<ICoursePath>(
    `courses/${slug}/`,
    changes,
  );
  return coursePath;
};

export async function deleteCoursePath(slug: string) {
  await requester().delete(`courses/${slug}/`);
}
